import { ComponentChildren, createContext } from "preact";
import { useContext } from "preact/hooks";

export interface HeadProps {
  children: ComponentChildren;
}

export const HEAD_CONTEXT = createContext<ComponentChildren[]>([]);

export function Head(props: HeadProps) {
  let context: ComponentChildren[];
  try {
    context = useContext(HEAD_CONTEXT);
  } catch (err) {
    throw new Error(
      "<Head> component is not supported in the browser, or during suspense renders.",
      { cause: err },
    );
  }
  context.push(props.children);
  return null;
}

// denoCacheMetadata={"headers":{"accept-ranges":"bytes","content-length":"568","age":"201399","via":"http/2 edgeproxy-h","access-control-allow-origin":"*","server-timing":"fetchSource;dur=6","cross-origin-embedder-policy":"same-origin","x-amz-replication-status":"COMPLETED","x-amz-server-side-encryption":"AES256","x-amz-version-id":"LfD6pLJMeSxHKdRo0dNypOZ5Ze2TQtXJ","x-cache":"Hit from cloudfront","referrer-policy":"strict-origin-when-cross-origin","server":"deno/gcp-us-east4","cache-control":"public, max-age=31536000, immutable","x-amz-cf-pop":"IAD61-P2","content-security-policy":"default-src 'none'; style-src 'unsafe-inline'; sandbox","vary":"Accept-Encoding, Origin","strict-transport-security":"max-age=63072000; includeSubDomains; preload","x-content-type-options":"nosniff","content-type":"application/typescript; charset=utf-8","x-frame-options":"DENY","date":"Thu, 06 Mar 2025 06:58:30 GMT","cross-origin-opener-policy":"same-origin","etag":"\"72998957dd4bed7c85c01504a8a9a25f\"","cross-origin-resource-policy":"same-origin","last-modified":"Fri, 22 Mar 2024 21:26:14 GMT","x-amz-cf-id":"L9tq5u0ZZbzy37E0NxMq75iMMWU8dYCtrif_Zo46j0ZjLmKRQLNnzA=="},"url":"https://deno.land/x/fresh@1.6.8/src/runtime/head.ts","time":1741445709}