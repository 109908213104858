import { ComponentChildren, VNode } from "preact";

export interface PartialProps {
  children?: ComponentChildren;
  /**
   * The name of the partial. This value must be unique across partials.
   */
  name: string;
  /**
   * Define how the new HTML should be applied.
   * @default {"replace"}
   */
  mode?: "replace" | "prepend" | "append";
}

export function Partial(props: PartialProps): VNode {
  // deno-lint-ignore no-explicit-any
  return props.children as any;
}
Partial.displayName = "Partial";

// denoCacheMetadata={"headers":{"etag":"\"3721122172060a9305ec10cb33de1a59\"","via":"http/2 edgeproxy-h","x-amz-cf-id":"dMBs3cL1WvwlUHl3XLq7dVhMQsHkvks2ziYxzjfCGP3JRQbV6_Emzg==","x-amz-cf-pop":"IAD61-P2","content-length":"508","x-amz-server-side-encryption":"AES256","x-amz-version-id":"uKOGSpJnZQIGP6Y2XkNHtOtGh88oC0iW","cross-origin-opener-policy":"same-origin","x-amz-replication-status":"COMPLETED","cache-control":"public, max-age=31536000, immutable","x-cache":"Hit from cloudfront","content-type":"application/typescript; charset=utf-8","x-frame-options":"DENY","content-security-policy":"default-src 'none'; style-src 'unsafe-inline'; sandbox","access-control-allow-origin":"*","cross-origin-embedder-policy":"same-origin","accept-ranges":"bytes","last-modified":"Fri, 22 Mar 2024 21:26:14 GMT","strict-transport-security":"max-age=63072000; includeSubDomains; preload","x-content-type-options":"nosniff","vary":"Accept-Encoding, Origin","referrer-policy":"strict-origin-when-cross-origin","server":"deno/gcp-us-east4","age":"201398","server-timing":"fetchSource;dur=8","date":"Thu, 06 Mar 2025 06:58:30 GMT","cross-origin-resource-policy":"same-origin"},"url":"https://deno.land/x/fresh@1.6.8/src/runtime/Partial.tsx","time":1741445708}